import { Flex, Heading, Image, Stack, Text, Box } from '@chakra-ui/react';

//* Images
import colorBG from 'assets/redesignImages/whoWeAre/color-bg.svg';
import background from 'assets/redesignImages/whoWeAre/background.svg';
import tablet from 'assets/redesignImages/whoWeAre/tablet.png';
import textBox from 'assets/redesignImages/whoWeAre/text-popup.svg';
import cloud from 'assets/redesignImages/whoWeAre/cloud.svg';
import leaf from 'assets/redesignImages/whoWeAre/leaf.svg';
import docker from 'assets/redesignImages/whoWeAre/docker.svg';
import chip from 'assets/redesignImages/whoWeAre/chip.svg';
import think from 'assets/redesignImages/whoWeAre/think.svg';
import computer from 'assets/redesignImages/whoWeAre/computer.svg';

const TopSection = () => (
  <Stack
    padding='1em 2em'
    backgroundColor='#06083e'
    w='100%'
    backgroundImage={`url(${colorBG}), url(${background})`}
    backgroundRepeat='no-repeat'
    backgroundSize='contain'
    backgroundPosition={{ base: 'top', md: 'center' }}
    position='relative'
  >
    <Flex
      direction={{ base: 'column', md: 'row' }}
      alignItems='center'
      maxH={{ base: 'auto', md: '70vh' }}
    >
      <Stack color='white' flex={1}>
        <Heading>WE OFFER SOLUTIONS TO EVERYDAY CHALLENGES</Heading>
        <Heading fontWeight='normal'>
          INTEGRATION WITH YOUR EXISTING SYSTEMS
        </Heading>
        {/* Desktop text block */}
        <Text
          color='black'
          backgroundImage={`url(${textBox})`}
          backgroundRepeat='no-repeat'
          width={523}
          height={264}
          padding='2em 1.2em 1em 2.2em'
          fontWeight='bold'
          display={{ base: 'none', md: 'inherit' }}
        >
          We are a resilient and committed team of passionate professionals
          operating efficiently, adapting to the dynamics and rapidly responding
          to new IT challenges with best possible solution!
        </Text>
      </Stack>
      <Image src={tablet} alt='tablet' w='356px' h='477px' />
      <Text
        color='white'
        textAlign='center'
        display={{ base: 'inherit', md: 'none' }}
        paddingBottom='1em'
      >
        We are a resilient and committed team of passionate professionals
        operating efficiently, adapting to the dynamics and rapidly responding
        to new IT challenges with best possible solution!
      </Text>
      <Text
        color='white'
        textAlign='center'
        display={{ base: 'inherit', md: 'none' }}
      >
        As a reliable partner we have delivered multiple projects for LabView,
        Control systems, Electrical Engineering, Systems Design and Engineering
        in a timely, collaborative and accurate manner, ensuring hight quality
        and sustainability.
      </Text>
    </Flex>
  </Stack>
);

const MidSection = () => {
  const makeCard = (title: string, text: string) => (
    <Stack gap='1em' maxWidth='335px' alignItems='center' pt='2em'>
      <Flex
        justifyContent='center'
        alignItems='center'
        width={243}
        height={131}
        backgroundImage={`url(${cloud})`}
        backgroundRepeat='no-repeat'
      >
        <Heading color='#34a10a' fontSize='1.2rem' transform='translateY(.5em)'>
          {title}
        </Heading>
      </Flex>
      <Text textAlign='center'>{text}</Text>
    </Stack>
  );

  type Coords = {
    top?: number | string;
    left?: number | string;
    bottom?: number | string;
    right?: number | string;
    transform?: string;
  };

  const positionedLeaf = ({
    top = 'auto',
    left = 'auto',
    right = 'auto',
    bottom = 'auto',
    transform,
  }: Coords) => {
    return (
      <Image
        src={leaf}
        alt='leaf'
        position='absolute'
        top={top}
        left={left}
        bottom={bottom}
        right={right}
        transform={transform}
        w='27px'
        h='29px'
      />
    );
  };

  const positionedText = (
    text: string,
    {
      top = 'auto',
      left = 'auto',
      right = 'auto',
      bottom = 'auto',
      transform,
    }: Coords
  ) => {
    return (
      <Text
        position='absolute'
        top={top}
        left={left}
        bottom={bottom}
        right={right}
        transform={transform}
        fontWeight='bold'
        fontSize='1.2rem'
        color='black'
      >
        {text}
      </Text>
    );
  };

  return (
    <Stack
      w='100%'
      alignItems='center'
      gap='5em'
      paddingBottom='3em'
      backgroundColor='white'
      display={{ base: 'none', md: 'flex' }}
    >
      <Flex width='100%' justifyContent='center' gap='1em'>
        {makeCard(
          'OUR MISSION',
          'We suggest the best solution to achieve excellence in your project execution ensuring high-quality standard and cost effective approach by capitalizing on building powerful synergies with our partners...'
        )}
        {makeCard(
          'OUR VISION',
          'Flawlessly function as reliable, intelligent and innovative hub offering unique IT solutions to create premium value for our customers'
        )}
      </Flex>
      <Flex
        w='172px'
        minH='172px'
        backgroundColor='#34A10A'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        borderRadius={100}
        fontSize='1.2rem'
        color='white'
        fontWeight='bold'
        position='relative'
      >
        OUR CORE VALUE
        <Box
          position='absolute'
          w='190px'
          h='190px'
          border='2px solid #34A10A'
          borderRadius={100}
        />
        {/* Top */}
        {positionedLeaf({ top: '-30px', left: '75px' })}
        {positionedText('QUALITY', { top: '-45px', left: '100px' })}
        {/* Right */}
        {positionedLeaf({
          top: '75px',
          right: '-30px',
          transform: 'rotate(90deg)',
        })}
        {positionedText('RELIABILITY', { top: '75px', right: '-165px' })}
        {/* Bottom right */}
        {positionedLeaf({
          top: '145px',
          right: '-0px',
          transform: 'rotate(125deg)',
        })}
        {positionedText('CUSTOMIZED SOLUTIONS', {
          top: '165px',
          right: '-280px',
        })}
        {/* Bottom left */}
        {positionedLeaf({
          bottom: '0',
          left: '-3px',
          transform: 'rotate(160deg)',
        })}
        {positionedText('ACCOUNTABILITY', {
          bottom: '10px',
          left: '-200px',
        })}
        {/* Left */}
        {positionedLeaf({
          top: '55px',
          left: '-30px',
          transform: 'rotate(270deg)',
        })}
        {positionedText('INTEGRITY', {
          top: '30px',
          left: '-140px',
        })}
      </Flex>
    </Stack>
  );
};

const BottomSection = () => {
  const makeCard = (src: string, text: string, bg: string, shadow: string) => (
    <Stack
      padding='2em'
      borderRadius='1rem'
      gap='1em'
      alignItems='center'
      backgroundColor={bg}
      boxShadow={`0 3px 12px ${shadow}`}
      flex={1}
    >
      <Image src={src} alt='card' />
      <Text color='white' textAlign='center'>
        {text}
      </Text>
    </Stack>
  );

  return (
    <Stack
      w='100%'
      pt='5em'
      marginTop='0 !important'
      backgroundColor='#320478'
      gap='3em'
      display={{ base: 'none', md: 'flex' }}
    >
      <Heading textAlign='center' color='white'>
        HOW OUR SOLUTIONS ARE DIFFERENT AND BETTER
      </Heading>
      <Flex justifyContent='center' gap='2em' p='2em'>
        {makeCard(
          think,
          'We offer a fresh and innovative way of thinking.',
          '#6b06d9',
          '#7A7A7A'
        )}
        {makeCard(
          computer,
          'We consider the hardware and software as equal partners in our solutions.',
          '#4b0498',
          '#7A7A7A'
        )}
        {makeCard(
          chip,
          'Our progressive philosophy, treats hardware and software as co-equal components of the system',
          '#2d045a',
          '#7A7A7A'
        )}
        {makeCard(
          docker,
          'Working in orchestra and not for each other.',
          '#0d0748',
          '#004CFF'
        )}
      </Flex>
      <Flex
        backgroundColor='#06083E'
        justifyContent='center'
        alignItems='center'
        padding='1em'
      >
        <Text color='white'>
          © 2022 www.greenwaveiot.com All Rights Reserved.
        </Text>
      </Flex>
    </Stack>
  );
};

const Footer = () => {
  return (
    <Box>
      <Text
        fontSize={{ base: '0.8rem', md: '1.0rem' }}
        color='white'
        textAlign='center'
        padding='1em'
      >
        © 2022 www.greenwaveiot.com All Rights Reserved.
      </Text>
    </Box>
  );
};

const WhoWeAre = () => {
  return (
    <Stack w='100%'>
      <TopSection />
      <MidSection />
      <BottomSection />
      <Footer />
    </Stack>
  );
};

export default WhoWeAre;
