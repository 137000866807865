import Careers from './Careers';
import Header from './Header';
import Home from './Home';
import Home2 from './Home2';
import Partners from './Partners';
import SolutionHeader from './SolutionHeader';
import Solutions from './Solutions';

import { HomeStyle, SolutionStyle } from './style';
import TransparentHeader from './TransparentHeader';
import WhoWeAre from './WhoWeAre';

const Wrapper = ({ children }: any) => {
  return (
    <HomeStyle>
      <Header />
      {children}
    </HomeStyle>
  );
};

const TransparentHeaderWrapper = ({ children }: any) => {
  return (
    <HomeStyle>
      <TransparentHeader />
      {children}
    </HomeStyle>
  );
};

const SolutionWrapper = ({ children }: any) => {
  return (
    <SolutionStyle>
      <SolutionHeader />
      {children}
    </SolutionStyle>
  );
};

export const HomePage = () => (
  <Wrapper>
    <Home />
  </Wrapper>
);

export const HomePage2 = () => (
  <Wrapper>
    <Home2 />
  </Wrapper>
);
export const WhoWeArePage = () => (
  <TransparentHeaderWrapper>
    <WhoWeAre />
  </TransparentHeaderWrapper>
);

export const SolutionsPage = () => (
  <SolutionWrapper>
    <Solutions />
  </SolutionWrapper>
);

export const PartnersPage = () => (
  <TransparentHeaderWrapper>
    <Partners />
  </TransparentHeaderWrapper>
);

export const CareersPage = () => (
  <TransparentHeaderWrapper>
    <Careers />
  </TransparentHeaderWrapper>
);
