import {
  Box,
  Flex,
  Heading,
  IconButton,
  Image,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

//? Images
//* Landing Images
import facebook from 'assets/redesignImages/home/facebook.png';
import linkedin from 'assets/redesignImages/home/linkedin.png';
import twitter from 'assets/redesignImages/home/twitter.png';
//* What we provide images
import macBookPro from 'assets/redesignImages/whatWeProvide/MacBook-Pro-mockup.png';
//* Solutions images
import background from 'assets/redesignImages/solutions/Group 1613.png';
//* How it works images
import curve from 'assets/redesignImages/howItWorks/curve.svg';
import iphone from 'assets/redesignImages/howItWorks/iphone.png';
import affiliate from 'assets/redesignImages/howItWorks/affiliate.svg';
import buy from 'assets/redesignImages/howItWorks/buy.svg';
import land from 'assets/redesignImages/howItWorks/land.svg';
import lease from 'assets/redesignImages/howItWorks/lease.svg';
import manage from 'assets/redesignImages/howItWorks/manage.svg';
import own from 'assets/redesignImages/howItWorks/own.svg';
import use from 'assets/redesignImages/howItWorks/use.svg';
//* Key points images
import first from 'assets/redesignImages/keypoints/first.png';
import second from 'assets/redesignImages/keypoints/second.png';
import third from 'assets/redesignImages/keypoints/third.png';
import fourth from 'assets/redesignImages/keypoints/fourth.png';
import fifth from 'assets/redesignImages/keypoints/fifth.png';
import sixth from 'assets/redesignImages/keypoints/sixth.png';
//* Why us section images
import one from 'assets/redesignImages/whyUs/1.svg';
import two from 'assets/redesignImages/whyUs/2.svg';
import three from 'assets/redesignImages/whyUs/3.svg';
import four from 'assets/redesignImages/whyUs/4.svg';
import five from 'assets/redesignImages/whyUs/5.svg';
import letter from 'assets/redesignImages/whyUs/letter.png';
//* Contact us images
import globe from 'assets/redesignImages/contactUs/globe.png';
import marker from 'assets/redesignImages/contactUs/marker.svg';

import { green } from 'styles/variables';
import AGPSTracker from 'components/Home/A_GPSTracker';
import ACMD from 'components/Home/A_CMD';
import ALed from 'components/Home/A_Led';
import AAgroD from 'components/Home/A_AgroD';
import AGlobe from 'components/Home/A_Globe';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useState } from 'react';

const Hero = () => {
  const [isLessThan768] = useMediaQuery('(max-width: 768px)');

  return (
    <Box
      w='100%'
      h={{ base: 'fit-content', md: 'calc(100vh - 100px)' }}
      position='relative'
      overflow='hidden'
      backgroundColor={{ base: 'white', md: '#06083e' }}
      padding={{ base: '3em 1em', md: '3em' }}
      z-index='0'
    >
      <Stack height='100%' justifyContent='flex-start'>
        {!isLessThan768 && <AGlobe />}
        <Heading
          as={motion.h2}
          initial={!isLessThan768 && { x: '-100vw' }}
          animate={!isLessThan768 && { x: 0, transition: { duration: 1 } }}
          color='white'
          letterSpacing='9.22px'
          fontSize={{ base: '25px', md: '96px' }}
          fontWeight='normal'
          textAlign={{ base: 'center', md: 'left' }}
          textShadow={{
            base: `-1px -1px 0 ${green}, 1px -1px 0 ${green}, -1px 1px 0 ${green}, 1px 1px 0 ${green}`,
            md: 'none',
          }}
        >
          TURN
          <br />
          TECHNOLOGY
        </Heading>
        <Heading
          as={motion.h2}
          initial={!isLessThan768 && { x: '-100vw' }}
          animate={
            !isLessThan768 && { x: 0, transition: { duration: 1, delay: 0.5 } }
          }
          color={{ base: 'white', md: green }}
          letterSpacing='9.22px'
          fontSize={{ base: '25px', md: '96px' }}
          fontWeight='normal'
          textAlign={{ base: 'center', md: 'left' }}
          textShadow={{
            base: `-1px -1px 0 ${green}, 1px -1px 0 ${green}, -1px 1px 0 ${green}, 1px 1px 0 ${green}`,
            md: 'none',
          }}
        >
          GREEN
        </Heading>
      </Stack>
      <Stack
        as={motion.div}
        initial={!isLessThan768 && { x: '100vw' }}
        animate={!isLessThan768 && { x: 0, transition: { duration: 1 } }}
        position='absolute'
        top={{ base: '20%', md: '40%' }}
        left={{ base: '1em', md: 'auto' }}
        right={{ base: 'auto', md: '2em' }}
        gap='1em'
      >
        {/* TODO: Add links here for socials */}
        <a href='https://www.facebook.com/profile.php?id=100067557438758&mibextid=LQQJ4d'>
          <Image
            src={facebook}
            alt='facebook'
            loading='lazy'
            width={{ base: '20px', md: '45px' }}
            height={{ base: '20px', md: '45px' }}
            filter={{ base: 'invert(1)', md: 'invert(0)' }}
          />
        </a>
        <a href='#'>
          <Image
            src={twitter}
            alt='twitter'
            loading='lazy'
            width={{ base: '20px', md: '45px' }}
            height={{ base: '20px', md: '45px' }}
            filter={{ base: 'invert(1)', md: 'invert(0)' }}
          />
        </a>
        <a href='https://www.linkedin.com/company/tenmilezone/'>
          <Image
            src={linkedin}
            alt='linkedIn'
            loading='lazy'
            width={{ base: '20px', md: '45px' }}
            height={{ base: '20px', md: '45px' }}
            filter={{ base: 'invert(1)', md: 'invert(0)' }}
          />
        </a>
      </Stack>
    </Box>
  );
};

const WhatWeProvide = () => {
  return (
    <Stack
      as='section'
      justifyContent='flex-start'
      alignItems='center'
      backgroundColor='#06083e'
      color='white'
      paddingTop='3em'
      maxHeight='86vh'
      position='relative'
    >
      <Heading
        textAlign='center'
        fontSize={{ base: '25px', md: '35px' }}
        zIndex={100}
      >
        WE OFFER SOLUTIONS <br />
        TO EVERYDAY CHALLENGES
      </Heading>
      <Flex
        paddingTop='2em'
        alignItems='center'
        justifyContent='center'
        gap={{ base: '1em', md: '20em' }}
        zIndex={100}
      >
        <Stack>
          <Heading fontSize={{ base: '25px', md: '30px' }} letterSpacing='4px'>
            Hardware
          </Heading>
          <Text fontSize={{ base: '25px', md: '30px' }}>as a service</Text>
        </Stack>
        <Stack>
          <Heading
            fontSize={{ base: '25px', md: '30px' }}
            letterSpacing='5.5px'
          >
            Software
          </Heading>
          <Text fontSize={{ base: '25px', md: '30px' }}>as a service</Text>
        </Stack>
      </Flex>
      <Image
        paddingTop={{ base: '2em', md: 0 }}
        src={macBookPro}
        alt='mac book pro'
        loading='lazy'
        transform='translateY(-3em)'
      />
      <Box
        position='absolute'
        width='324px'
        height='201px'
        zIndex={10}
        top={{ base: '-5px', md: 0 }}
        backgroundColor='#0011FF'
        borderRadius={100}
        filter='blur(20px)'
        boxShadow='0px 3px 99px #0011FF'
        opacity={0.24}
      />
    </Stack>
  );
};

const solutions = [<ACMD />, <AGPSTracker />, <AAgroD />, <ALed />];

const Solutions = () => {
  const [currentItem, setCurrentItem] = useState(0);

  const handleNext = () => {
    if (currentItem === 0) {
      setCurrentItem(1);
    } else if (currentItem === 1) {
      setCurrentItem(2);
    } else if (currentItem === 2) {
      setCurrentItem(3);
    } else if (currentItem === 3) {
      setCurrentItem(0);
    }
  };

  const handlePrevious = () => {
    if (currentItem === 0) {
      setCurrentItem(3);
    } else if (currentItem === 1) {
      setCurrentItem(0);
    } else if (currentItem === 2) {
      setCurrentItem(1);
    } else if (currentItem === 3) {
      setCurrentItem(2);
    }
  };

  let itemToDisplay: JSX.Element;
  let textToDisplay: string;

  if (currentItem === 0) {
    itemToDisplay = solutions[0];
    textToDisplay = 'CMD';
  } else if (currentItem === 1) {
    itemToDisplay = solutions[1];
    textToDisplay = 'GPS TRACKER';
  } else if (currentItem === 2) {
    itemToDisplay = solutions[2];
    textToDisplay = 'AGRO D';
  } else if (currentItem === 3) {
    itemToDisplay = solutions[3];
    textToDisplay = 'LED';
  }

  return (
    <Stack paddingTop='3em' zIndex={-10} backgroundColor='#fcfff2'>
      <Heading textAlign='center'>SOLUTIONS</Heading>
      <Stack
        textAlign='center'
        position='relative'
        alignItems='center'
        paddingTop={{ base: '5em', md: '20em' }}
      >
        {/* Only visible on desktop */}
        <Flex gap='4em' zIndex={50} display={{ base: 'none', md: 'flex' }}>
          <Stack>
            <Text>CMD</Text>
            <ACMD />
          </Stack>
          <Stack>
            <Text>GPS TRACKER</Text>
            <AGPSTracker />
          </Stack>
        </Flex>
        {/* Only visible on desktop */}
        <Flex gap='4em' zIndex={50} display={{ base: 'none', md: 'flex' }}>
          <Stack>
            <Text>AGRO D</Text>
            <AAgroD />
          </Stack>
          <Stack>
            <Text>LED</Text>
            <ALed />
          </Stack>
        </Flex>

        {/* Only visible on mobile */}
        <Flex zIndex={50} display={{ base: 'flex', md: 'none' }}>
          <Stack
            width={{ base: '175px', md: 'auto' }}
            height={{ base: '175px', md: 'auto' }}
            justifyContent='center'
            alignItems='center'
          >
            <Text>{textToDisplay!}</Text>
            {itemToDisplay!}
          </Stack>
        </Flex>
        <IconButton
          aria-label='Next'
          icon={<ArrowRightIcon />}
          position='absolute'
          right='0'
          top='55%'
          backgroundColor='transparent'
          display={{ base: 'flex', md: 'none' }}
          onClick={handleNext}
          zIndex={1000}
        />
        <IconButton
          aria-label='Previous'
          icon={<ArrowLeftIcon />}
          position='absolute'
          left='0'
          top='55%'
          backgroundColor='transparent'
          display={{ base: 'flex', md: 'none' }}
          onClick={handlePrevious}
          zIndex={1000}
        />
        {/* Mobile section over */}

        {/* Background color image */}
        <Image src={background} position='absolute' top={0} zIndex={5} />
        {/* Center white box */}
        <Box
          borderRadius='100%'
          width={{ base: '250px', md: '800px' }}
          height={{ base: '250px', md: '800px' }}
          position='absolute'
          top={{ base: '60px', md: 40 }}
          zIndex={10}
          backgroundColor='rgba(255, 255, 255, 0.5)'
        />
        {/* Dashed outline on the side */}
        <Box
          borderRadius='100%'
          width={{ base: '300px', md: '850px' }}
          height={{ base: '300px', md: '850px' }}
          position='absolute'
          top={{ base: '35px', md: '135px' }}
          zIndex={20}
          backgroundColor='transparent'
          border='2px dashed white'
        />
      </Stack>
    </Stack>
  );
};

const HowItWorks = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  const makeCard = (src: string, shadowColor: string, title: string) => (
    <Stack maxWidth={{ base: '100px', md: '133px' }}>
      <Flex
        margin={{ base: '0em', md: '25em 0 1.5em 0' }}
        padding='1em'
        boxShadow={`0px 3px 15px ${shadowColor}`}
        width={{ base: '100px', md: '133px' }}
        height={{ base: '105px', md: '138px' }}
        justifyContent='center'
        alignItems='center'
      >
        <Image src={src} alt='card' />
      </Flex>
      <Text
        color='white'
        fontSize={{ base: '1rem', md: 'auto' }}
        textAlign='center'
        noOfLines={[1, 1, 2]}
      >
        {title}
      </Text>
    </Stack>
  );

  const mobileCards = [
    makeCard(own, '#00FFF7', 'OWN'),
    makeCard(use, '#B4F300EB', 'USE'),
    makeCard(manage, '#DD00FFF0', 'MANAGE'),
    makeCard(affiliate, '#7A7A7A', 'AFFILIATE PROGRAM'),
  ];

  const handleNextCard = () =>
    setActiveCardIndex((prev) => {
      if (prev + 1 > mobileCards.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });

  const handlePrevCard = () =>
    setActiveCardIndex((prev) => {
      if (prev - 1 < 0) {
        return mobileCards.length - 1;
      } else {
        return prev - 1;
      }
    });

  return (
    <Stack position='relative' paddingTop='10em' backgroundColor='#fcfff2'>
      <Heading
        textAlign='center'
        zIndex={20}
        paddingBottom={{ base: '6em', md: '0em' }}
      >
        HOW IT WORKS
      </Heading>
      <Image
        src={curve}
        alt='background curve'
        zIndex={10}
        position='absolute'
        bottom={{ base: '0%', md: 'auto' }}
        transform='scale(1.1)'
      />
      <Image
        src={buy}
        alt='buy'
        position='absolute'
        top={{ base: '17.5em', md: '25em' }}
        left='auto'
        right={{ base: '6em', md: '35em' }}
        width={{ base: '75px', md: 'auto' }}
        zIndex={20}
      />
      <Image
        src={lease}
        alt='lease'
        position='absolute'
        top={{ base: '16.2em', md: '20em' }}
        left='auto'
        right={{ base: '4em', md: '27em' }}
        width={{ base: '50px', md: 'auto' }}
        zIndex={20}
      />
      <Image
        src={land}
        alt='land'
        position='absolute'
        top={{ base: '16em', md: '17em' }}
        left='auto'
        right={{ base: '0.5em', md: '15em' }}
        width={{ base: '50px', md: 'auto' }}
        zIndex={20}
      />
      <Flex
        paddingX={{ base: '1em', md: '5em' }}
        alignItems='center'
        width='100%'
        gap='3em'
        zIndex={10}
      >
        <Image
          src={iphone}
          maxHeight='75rem'
          position={{ base: 'absolute', md: 'inherit' }}
          bottom='0px'
          left='0px'
          width={{ base: '150px', md: 'auto' }}
        />
        {/* Desktop cards */}
        <Flex
          alignItems='flex-start'
          justifyContent='center'
          flex={1}
          gap='3em'
          display={{ base: 'none', md: 'flex' }}
        >
          {makeCard(own, '#00FFF7', 'OWN')}
          {makeCard(use, '#B4F300EB', 'USE')}
          {makeCard(manage, '#DD00FFF0', 'MANAGE')}
          {makeCard(affiliate, '#7A7A7A', 'AFFILIATE PROGRAM')}
        </Flex>
        {/* Mobile Cards */}
        <Flex
          w='100%'
          justifyContent='flex-end'
          alignItems='center'
          gap='0.5em'
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            aria-label='Previous'
            icon={<ArrowLeftIcon />}
            backgroundColor='transparent'
            color='white'
            display={{ base: 'flex', md: 'none' }}
            onClick={handlePrevCard}
            zIndex={1000}
            marginBottom='2em'
          />
          {mobileCards[activeCardIndex]}
          <IconButton
            aria-label='Next'
            icon={<ArrowRightIcon />}
            backgroundColor='transparent'
            color='white'
            display={{ base: 'flex', md: 'none' }}
            onClick={handleNextCard}
            zIndex={1000}
            marginBottom='2em'
          />
        </Flex>
      </Flex>
    </Stack>
  );
};

const KeyPoints = () => {
  const makeCard = (src: string, text: string) => (
    <Flex
      gap='1em'
      alignItems='center'
      justifyContent='center'
      flex={1}
      maxHeight='15rem'
    >
      <Image src={src} alt='card' width='35px' />
      <Text padding='1em 3em 1em 1em'>{text}</Text>
    </Flex>
  );

  return (
    <Stack paddingTop='2em' paddingBottom='4em' backgroundColor='white'>
      <Heading textAlign='center' fontSize='2.25rem'>
        USE YOUR{' '}
        <span style={{ fontSize: '2.25rem', color: '#00a7e6' }}>
          MONITORING
        </span>{' '}
        DATA TO MAKE
        <br />
        INFORMED DECISIONS FOR YOUR BUSINESS
      </Heading>
      <Flex
        paddingTop={{ base: '1em', md: '5em' }}
        paddingX={{ base: '1em', md: '7em' }}
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: '1em', md: '0' }}
      >
        {makeCard(
          first,
          'Data driven decision making is the key to your business prosperity'
        )}
        {makeCard(
          second,
          'Rigorous and focused data will inform your most efficient business decisions to make necessary adjustments to your business model...'
        )}
        {makeCard(
          third,
          'Build your customized monitoring data flow to enhance the exponential growth potential for your business'
        )}
      </Flex>
      <Flex
        paddingTop={{ base: '1em', md: '2em' }}
        paddingX={{ base: '1em', md: '7em' }}
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: '1em', md: '0' }}
      >
        {makeCard(
          fourth,
          'Let the smart technology handle your business growth with accurate data monitoring'
        )}
        {makeCard(
          fifth,
          'Become a Leader of Customer Market and capitalize on consumer market!'
        )}
        {makeCard(
          sixth,
          'Let’s analyze data to shape bright future for your business'
        )}
      </Flex>
    </Stack>
  );
};

const WhyUs = () => {
  const makeListItem = (src: string, text: string) => (
    <Flex alignItems='center'>
      <Image src={src} alt='list item' />
      <Text
        fontSize={{ base: '.8rem', md: 'auto' }}
        fontWeight={{ base: 'bold', md: 'normal' }}
      >
        {text}
      </Text>
    </Flex>
  );

  return (
    <Stack
      position='relative'
      paddingTop='2em'
      paddingBottom={{ base: '2em', md: '10em' }}
      backgroundColor='white'
    >
      <Heading textAlign='center'>WHY GREENWAVEIOT</Heading>
      <Flex
        justifyContent='flex-end'
        paddingY={{ base: '0', md: '5em' }}
        paddingTop={{ base: '20em', md: '0' }}
        zIndex={10}
        paddingRight={{ base: 0, md: '20em' }}
        paddingX={{ base: '1em', md: '0' }}
      >
        <Stack
          padding='2em'
          boxShadow='0 0 10px rgba(0,0,0,0.5)'
          borderRadius={15}
          maxWidth='500px'
          backgroundColor='white'
          gap={{ base: '0em', md: '1em' }}
        >
          {makeListItem(one, 'MAXIMUM FLEXIBILITY AND NON-BINDING MECHANISMS')}
          {makeListItem(
            two,
            'GENERATE REVENUE FROM OUR INTELLECTUAL INVESTMENT!'
          )}
          {makeListItem(
            three,
            'JUST BUY A SOFTWARE / HARDWARE AS A SERVICE TO TAKE YOUR BUSINESS TO THE NEXT LEVEL'
          )}
          {makeListItem(
            four,
            'SELECT COMMITMENT TO THE SOFTWARE PURELY FOCUSED ON YOUR SPECIFIC BUSINESS NEEDS'
          )}
          {makeListItem(
            five,
            'PAY ONLY FOR THE SERVICE YOUR BUSINESS NEEDS, SPEND YOUR FUNDS SMART!'
          )}
        </Stack>
      </Flex>
      <Image
        src={letter}
        alt='letter'
        position='absolute'
        zIndex={0}
        top={{ base: '10%', md: -250 }}
        transform={{ base: 'none', md: 'scale(0.6)' }}
      />
    </Stack>
  );
};

const ContactUs = () => {
  return (
    <Stack
      backgroundColor='#06083E'
      alignItems='center'
      paddingTop='3em'
      paddingBottom='1em'
      paddingX={{ base: '1em', md: '3em' }}
      color='white'
      width='100vw'
      boxShadow='0 -5px 6px #0011FF'
      position='relative'
    >
      <Heading textAlign='center'>CONTACT US</Heading>
      <Image
        src={globe}
        alt='globe'
        width='100%'
        paddingTop='3em'
        maxWidth={{ base: '100%', md: '75%' }}
      />
      <Image
        src={marker}
        alt='marker'
        position='absolute'
        top={{ base: '26%', md: '44%' }}
        left={{ base: '11%', md: '24%' }}
        width={{ base: '70px', md: 'auto' }}
      />
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent='space-between'
        width='100%'
        paddingY='1em'
      >
        <Text fontSize='1.0rem'>
          address: 500 N. Brand Blvd Suite 2000 Glendale CA 91203
        </Text>
        <Text fontSize='1.0rem'>phone number: 818 934 0010</Text>
        <Text fontSize='1.0rem'>email: info@greenwaveiot.com</Text>
      </Flex>
      <Box
        width='100vw'
        height='1px'
        backgroundColor='#0011FF'
        boxShadow='0 -5px 6px #0011FF'
      />
      <Text fontSize={{ base: '0.8rem', md: '1.0rem' }} paddingTop='1em'>
        © 2022 www.greenwaveiot.com All Rights Reserved.
      </Text>
    </Stack>
  );
};

const Home = () => {
  return (
    <>
      <Hero />
      <WhatWeProvide />
      <Solutions />
      <HowItWorks />
      <KeyPoints />
      <WhyUs />
      <ContactUs />
    </>
  );
};

export default Home;
