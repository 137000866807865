import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Image,
  Box,
  Input,
  Textarea,
  IconButton,
} from '@chakra-ui/react'
//* Images
import facebook from 'assets/redesignImages/home/facebook.png'
import linkedin from 'assets/redesignImages/home/linkedin.png'
import twitter from 'assets/redesignImages/home/twitter.png'
import background from 'assets/redesignImages/careers/background.jpeg'
import { Controller, useForm } from 'react-hook-form'
import { ReactElement, useState } from 'react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ArrowRightIcon, ArrowLeftIcon } from '@chakra-ui/icons'

interface JobInfo {
  title: string
  role: string
  description: string
}

const Careers = () => {
  return (
    <>
      <Stack
        minHeight={{ base: 'auto', md: 'calc(100vh - 100px)' }}
        justifyContent={{ base: 'normal', md: 'center' }}
        alignItems='center'
        position='relative'
        padding={{ base: '1em', md: '0' }}
      >
        <a href='#jobs' style={{ zIndex: 100 }}>
          <Button
            backgroundColor={{ base: 'transparent', md: '#1E2050' }}
            boxShadow={{ base: 'none', md: '0 0 15px #004CFF' }}
            border={{ base: '3px solid white', md: 'none' }}
            color='white'
            w={{ base: 'auto', md: '525px' }}
            h='80px'
            fontSize='30px'
            borderRadius={{ base: '15px', md: 'inherit' }}
            _hover={{
              backgroundColor: 'transparent',
              border: '3px solid white',
              boxShadow: 'none',
            }}
          >
            Join our team
          </Button>
        </a>
        {/* Social icons */}
        <Stack
          position='absolute'
          top={{ base: '10%', md: '40%' }}
          left='auto'
          right={{ base: '1em', md: '2em' }}
          gap='1em'
          zIndex={100}
        >
          {/* TODO: Add links here for socials */}
          <a href='https://www.facebook.com/profile.php?id=100067557438758&mibextid=LQQJ4d'>
            <Image
              src={facebook}
              alt='facebook'
              loading='lazy'
              width={{ base: '20px', md: '45px' }}
              height={{ base: '20px', md: '45px' }}
            />
          </a>
          <a href='#'>
            <Image
              src={twitter}
              alt='twitter'
              loading='lazy'
              width={{ base: '20px', md: '45px' }}
              height={{ base: '20px', md: '45px' }}
            />
          </a>
          <a href='https://www.linkedin.com/company/tenmilezone/'>
            <Image
              src={linkedin}
              alt='linkedIn'
              loading='lazy'
              width={{ base: '20px', md: '45px' }}
              height={{ base: '20px', md: '45px' }}
            />
          </a>
        </Stack>
        {/* Background image */}
        <Image
          src={background}
          alt='background'
          position='absolute'
          top={0}
          left={0}
          zIndex={10}
          maxHeight='calc(100vh - 100px)'
          width='100%'
          objectFit='cover'
          display={{ base: 'none', md: 'inherit' }}
        />
        <Box
          width='100%'
          height='calc(100vh - 100px)'
          position='absolute'
          top={0}
          left={0}
          zIndex={20}
          backgroundColor='rgba(0, 0, 0, .4)'
          display={{ base: 'none', md: 'inherit' }}
        />
      </Stack>
      <JobSection />
      <Footer />
    </>
  )
}

const jobsData = [
  {
    title: 'Frontend Engineer',
    role: 'SENIOR FRONTEND ENGINEER',
    description:
      'We are looking for an experienced JavaScript developer who is proficient with React.js. The primary focus of the selected candidate would be on developing user interface components implementing and executing them following well-known React.js workflows (such as Flux or Redux). Also ensuring that these components and the overall application are robust and easy to manage. A commitment to collaborative problem solving, sophisticated design, and quality products are important.',
  },
  {
    title: 'Frontend Engineer',
    role: 'FRONTEND ENGINEER',
    description:
      'We are looking for an experienced JavaScript developer who is proficient with React.js. The primary focus of the selected candidate would be on developing user interface components implementing and executing them following well-known React.js workflows (such as Flux or Redux). Also ensuring that these components and the overall application are robust and easy to manage. A commitment to collaborative problem solving, sophisticated design, and quality products are important.',
  },
  {
    title: 'Frontend Engineer',
    role: 'Junior FRONTEND ENGINEER',
    description:
      'We are looking for an experienced JavaScript developer who is proficient with React.js. The primary focus of the selected candidate would be on developing user interface components implementing and executing them following well-known React.js workflows (such as Flux or Redux). Also ensuring that these components and the overall application are robust and easy to manage. A commitment to collaborative problem solving, sophisticated design, and quality products are important.',
  },
]

const JobSection = () => {
  const [jobIndex, setJobIndex] = useState(0)

  const makeCard = (info: JobInfo) => (
    <Flex direction={{ base: 'column', md: 'row' }} gap='1em'>
      {/* Tile and role on the left */}
      <Stack minWidth={{ base: 'auto', md: '220px' }}>
        <Heading fontSize='20px'>{info.title}</Heading>
        <Text fontSize='12px'>{info.role}</Text>
      </Stack>
      {/* Description on the left */}
      <Text fontSize='15px'>{info.description}</Text>
    </Flex>
  )

  const handleNext = () => setJobIndex((prev) => (prev + 1) % jobsData.length)
  const handlePrev = () =>
    setJobIndex(
      (prev) =>
        (prev + (jobsData.length - (1 % jobsData.length))) % jobsData.length
    )

  return (
    <Stack padding={{ base: '3em 1em', md: '2em 5em' }} id='jobs'>
      {/* Desktop jobs section */}
      <Stack
        padding={{ base: '1em', md: '2em 5em' }}
        color='white'
        boxShadow='0px 3px 19px #0032FF'
        borderRadius='10px'
        gap='2em'
        display={{ base: 'none', md: 'flex' }}
      >
        {jobsData.map((job) => makeCard(job))}
      </Stack>

      {/* Mobile jobs secton */}

      <Stack
        backgroundColor='rgba(0,0,0,0.6)'
        display={{ base: 'flex', md: 'none' }}
        boxShadow='0 0 10px white'
        color='white'
        padding='1em'
        borderRadius='10px'
      >
        <Flex justifyContent='center' gap='1em'>
          <IconButton
            aria-label='Previous'
            icon={<ArrowLeftIcon />}
            backgroundColor='transparent'
            onClick={handlePrev}
          />
          <IconButton
            aria-label='Next'
            icon={<ArrowRightIcon />}
            backgroundColor='transparent'
            onClick={handleNext}
          />
        </Flex>
        <Text fontSize='sm'>{jobsData[jobIndex].title}</Text>
        <Text pb='1em' fontSize='md'>
          {jobsData[jobIndex].role}
        </Text>
        <Text fontSize='md'>{jobsData[jobIndex].description}</Text>
      </Stack>

      <FormSection />
    </Stack>
  )
}

const FormSection = () => {
  const formCurrentDefaultValues = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    message: '',
    email: '',
    file: '',
  }

  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      ...formCurrentDefaultValues,
    },
  })

  const handleSubmitRef = () => {
    toast('Application received!🚀 Stay tuned for lift-off! 🌟')
    reset()
  }

  return (
    <Stack
      alignItems='center'
      padding='20px'
      // paddingY='3em'
      // backgroundColor='white'
      position='relative'
      // minHeight='100vh'
      // maxHeight='100vh'
      // overflow='hidden'
      display={{ base: 'none', md: 'flex' }}
    >
      <ToastContainer />

      <Heading color='#fff' fontSize='2.4rem'>
        JOIN OUR TEAM
      </Heading>
      <Text color='#FFF' paddingBottom='1.5em' fontSize='1.4rem'>
        Make a Difference. Work with one of the most trusted and dedicated teams
      </Text>
      {/* Form card */}
      <form onSubmit={handleSubmit(handleSubmitRef)}>
        <Stack
          padding='2em'
          boxShadow='5px 5px 42px #0101015E'
          maxWidth='575px'
          width='100%'
          borderRadius='10px'
          gap='1.5em'
          zIndex={100}
          backgroundColor='white'
        >
          <Controller
            name='firstName'
            key='firstName'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Input
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='First name *'
                required
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name='lastName'
            key='lastName'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Input
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='Last name *'
                required
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name='jobTitle'
            key='jobTitle'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Input
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='Job Title *'
                required
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name='email'
            key='email'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Input
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='Email *'
                required
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name='message'
            key='message'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Textarea
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='Message'
                resize='none'
                height='134px'
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name='file'
            key='file'
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Input
                accept='.pdf, .doc, .docx'
                type='file'
                borderColor='#040D2F'
                focusBorderColor='#040D2F'
                placeholder='File *'
                required
                padding='5px'
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Button
            type='submit'
            backgroundColor='#06083E'
            _hover={{ backgroundColor: '#080b4f' }}
            color='white'
          >
            Send message
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}

const Footer = () => {
  return (
    <Box>
      <Text
        fontSize={{ base: '0.8rem', md: '1.0rem' }}
        color='white'
        textAlign='center'
        padding='1em'
      >
        © 2022 www.greenwaveiot.com All Rights Reserved.
      </Text>
    </Box>
  )
}

export default Careers
