import styled from '@emotion/styled';
import { borderRadius, green, greenHover } from 'styles/variables';

const headerHeight = '100px';

export const HomeStyle = styled.div`
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: #06083e;
  scroll-behavior: smooth;
`;

export const SolutionStyle = styled.div`
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: white;
  scroll-behavior: smooth;
`;

export const HomePageStyle = styled.section`
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
  overflow: hidden;
  background-color: #06083e;
  padding: 3em;
  z-index: 0;
`;

export const HeaderStyle = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${headerHeight};
  background-color: transparent;
  color: #fff;
  padding: 1em;
  position: relative;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    background-color: white;
  }

  .logo {
    cursor: pointer;
  }

  nav {
    margin-right: 2em;
  }

  nav a:not(.get-in-touch) {
    color: ##f7f7f9;
    padding: 1.75em 0.3em;
  }

  nav a.active {
    font-weight: bold;
    color: #f3f3f6;
    padding: 1.75em 0.5em;
    border-bottom: 1px solid ${green};
    transform: translateY(1px);
  }

  nav a:not(.get-in-touch):hover {
    // font-weight: bold;
    border-bottom: 1px solid ${green};
    transform: translateY(1px);
  }

  .get-in-touch {
    padding: 8px 20px;
    border-radius: ${borderRadius};
    color: #fff;
    background-color: ${green};
    transition: background-color 0.5 ease;
  }

  .get-in-touch:hover {
    background-color: ${greenHover};
  }
`;

export const TransparentHeaderStyle = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${headerHeight};
  background-color: transparent;
  color: #fff;
  padding: 1em;
  position: relative;
  z-index: 1000;

  .logo {
    cursor: pointer;
  }

  nav {
    margin-right: 2em;
  }

  nav a:not(.get-in-touch) {
    color: ##f7f7f9;
    padding: 1.75em 0.3em;
  }

  nav a.active {
    font-weight: bold;
    color: #f3f3f6;
    padding: 1.75em 0.5em;
    border-bottom: 1px solid ${green};
    transform: translateY(1px);
  }

  nav a:not(.get-in-touch):hover {
    // font-weight: bold;
    border-bottom: 1px solid ${green};
    transform: translateY(1px);
  }

  .get-in-touch {
    padding: 8px 20px;
    border-radius: ${borderRadius};
    color: #fff;
    background-color: ${green};
    transition: background-color 0.5 ease;
  }

  .get-in-touch:hover {
    background-color: ${greenHover};
  }
`;

export const SolutionHeaderStyle = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${headerHeight};
  background-color: white;
  color: #707070;
  padding: 1em;
  position: relative;
  z-index: 1000;
  box-shadow: 0 0 35px #000000fc;

  .logo {
    cursor: pointer;
  }

  nav {
    margin-right: 2em;
  }

  nav a:not(.get-in-touch) {
    color: #707070;
    padding: 1.75em 0.3em;
  }

  nav a.active {
    font-weight: bold;
    color: #707070;
    padding: 1.75em 0.5em;
    border-bottom: 1px solid ${green};
    transform: translateY(1px);
  }

  nav a:not(.get-in-touch):hover {
    // font-weight: bold;
    border-bottom: 1px solid ${green};
    transform: translateY(1px);
  }

  .get-in-touch {
    padding: 8px 20px;
    border-radius: ${borderRadius};
    color: #fff;
    background-color: ${green};
    transition: background-color 0.5 ease;
  }

  .get-in-touch:hover {
    background-color: ${greenHover};
  }
`;

export const CompanyStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .company-text-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ebf5ff;
  }

  .company-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px 30px;

    .images {
      justify-content: space-around;
      display: flex;
      flex-direction: row;
      width: 100%;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
          font-size: 14px;
          color: #797979;
        }
        h3 {
          font-size: 12px;
          color: #797979;
        }
      }
    }
  }

  .company-text h4 {
    margin-bottom: 35px;
    color: #797979;
    transition: all 0.5s ease-out 0.5s;
  }
  .company-text p {
    margin-bottom: 5px;
    text-align: start;
    font-size: 16px;
    color: #797979;
    transition: all 0.5s ease-out 0.7s;
  }

  .company-text a {
    padding: 10px 15px;
    border: 1px solid;
    border-radius: 20px;
    color: #797979;
    transition: all 0.5s ease-out 0.9s;
  }

  .company-images {
    width: 100%;
    height: 100%;
    background: none;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .company-images img {
    width: 95%;
    transition: all 0.5s ease-out 0.7s;
  }
`;

export const CompKeysStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .keys-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ebf5ff;
  }

  .keys-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px 30px;
  }

  .keys-text h4 {
    margin-bottom: 35px;
    color: #797979;
    transition: all 0.5s ease-out 0.5s;
  }
`;

export const PhonesStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .phones-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ebf5ff;
  }

  .phones-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px 30px;
  }

  .keys-text h4 {
    margin-bottom: 25px;
    color: #797979;
    transition: all 0.5s ease-out 0.5s;
  }
`;

export const AboutUsStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .about-bg {
    width: 40%;
    height: 100%;
    background-color: #040d2f;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      transition: all 0.5s ease-out 0.7s;
    }
  }

  .about-text-wrap {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
  }

  .about-text {
    width: 80%;
  }

  .about-text h4 {
    margin-bottom: 20px;
    opacity: 0;
    transition: all 0.5s ease-out 0.5s;
    display: inline-block;
  }

  .about-text p {
    opacity: 0;
    transition: all 0.5s ease-out 0.7s;
    color: #555;
    margin-bottom: 20px;
  }
`;

export const WhoWeStyle = styled.div`
  width: 100%;
  height: 100%
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  
  .whowe-wrap {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .whowe-bg {
    background-color: #fff;
    height: 100%
    width: 35vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .whowe-text {
      display: flex;
      flex-direction: column;
      p {
      padding: 0px 25px;
      }
      .risk {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 25px;
        color: #ffffff;
        p {
          font: Montserrat;
          font-size: 1vw !important;
          letter-spacing: 0px;
          color: #040D2F;
        }
        .zero {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #040D2F;
          font-size: 1.7vw;
          font-weight: bold;
          height: 4vw;
          width: 4vw;
          background-color: #31C78457;
          border-radius: 50%;
        }
      }
      .texts {
        p {
          padding: 10px 25px;
          text-align: start;
          letter-spacing: 0px;
          color: #040D2F;
          position: relative;
        }
        p::before {
          display: inline-block;
          content: '';
          position:absolute;
          left: 15px;
          top: 15px;
          -webkit-border-radius: 0.375rem;
          border-radius: 0.375rem;
          height: 8px;
          width: 8px;
          margin-right: 0.5rem;
          background-color: #31C78457;
      }
      }
    }
  }

  .whowe-img {
    height: 100%;
    width: 65vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    img {
      width: 100%;
      transition: all 0.5s ease-out 0.7s;
    }
  }
`;

export const ContactUsStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;

  .contact-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact-bg {
    background-color: #fff;
    height: 100%;
    width: 35vw;
    display: flex;
    padding: 0px 20px;
    margin-left: 20px;
    border-left: 1px solid #1bb777;
    .contact-text {
      display: flex;
      flex-direction: column;
      p {
        padding: 0px 25px;
      }
    }
  }
  .contact-img {
    height: 100%;
    width: 65vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    img {
      width: 100%;
      transition: all 0.5s ease-out 0.7s;
    }
  }
`;

export const HowItWorksStyle = styled.div`
  width: 100%;
  height: 100%;
  h2 {
    position: absolute;
    left: 40px;
    top: 40px;
    font-size: 16px;
    color: white;
    text-align: center;
  }

  .how-it-works-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .images {
      display: flex;
      justify-content: space-between;
      text-align: center;
      .box {
        display: flex;
        flex-direction: column;
        background: #040d2f 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 15px;
        margin-right: 20px;
      }
    }
  }
`;

export const CareersStyle = styled.div`
  color: #fff;
  .careers-bg-img {
    width: 100%;
    height: calc(100vh - ${headerHeight});
  }
  .front-sen {
    padding: 0 60px;
    background: #040d2f;
    img {
      margin: 30px auto;
      padding: 30px 0;
    }
    h1 {
      font-size: 35px;
      text-align: center;
    }
    h2 {
      font-size: 25px;
      text-align: center;
    }
    .look-at {
      font-size: 20px;
      padding: 30px 0;
    }
    .flex-container {
      display: flex;
      justify-content: space-between;
      padding: 0 0 60px 0;
      .resp,
      .qual {
        width: 100%;
      }
      .heading {
        padding: 0 0 30px 0;
      }
    }
    .paragraph {
      padding: 0 0 60px 0;
    }
  }
  .secondPart {
    margin: 30px 0 0 0;
  }
  .thirdPart {
    margin: 30px 0 0 0;
  }
`;
