import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';

import background from 'assets/redesignImages/partner/background.png';
import curve from 'assets/redesignImages/partner/curve.svg';
//* social icons
import facebook from 'assets/redesignImages/home/facebook.png';
import linkedin from 'assets/redesignImages/home/linkedin.png';
import twitter from 'assets/redesignImages/home/twitter.png';

const TopSection = () => {
  return (
    <Stack position='relative'>
      <Image
        src={background}
        alt='background'
        position='absolute'
        width='100%'
        maxHeight='100vh'
        objectFit='cover'
        top='-100px'
        left={0}
        zIndex={0}
        display={{ base: 'none', md: 'inherit' }}
      />
      <Box
        width='100%'
        height='100vh'
        position='absolute'
        top='-108px'
        left={0}
        zIndex={10}
        backgroundColor='rgba(0, 0, 0, 0.4)'
        display={{ base: 'none', md: 'inherit' }}
      />
      <Flex
        width='100%'
        height={{ base: 'auto', md: 'calc(100vh - 100px)' }}
        justifyContent='center'
        alignItems='center'
        zIndex={20}
        position='relative'
        padding={{ base: '1em 0em', md: '0' }}
      >
        <Heading
          color='white'
          maxW='600px'
          textAlign={{ base: 'left', md: 'center' }}
          letterSpacing='1.75px'
          fontSize={{ base: 'xl', md: 'inherit' }}
          padding={{ base: '0 4em 0 0.5em', md: '0' }}
        >
          WE WANT OUR PARTNERS TO BE A RELIABLE SOLUTION PROVIDERS FOR THEIR
          CUSTOMERS.
        </Heading>
        <Stack
          position='absolute'
          gap={{ base: '0.5em', md: '1em' }}
          top={{ base: '10%', md: '40%' }}
          left='auto'
          right={{ base: '1em', md: '2em' }}
        >
          {/* TODO: Add links here for socials */}
          <a href='https://www.facebook.com/profile.php?id=100067557438758&mibextid=LQQJ4d'>
            <Image
              src={facebook}
              alt='facebook'
              loading='lazy'
              width={{ base: '20px', md: '45px' }}
              height={{ base: '20px', md: '45px' }}
            />
          </a>
          <a href='#'>
            <Image
              src={twitter}
              alt='twitter'
              loading='lazy'
              width={{ base: '20px', md: '45px' }}
              height={{ base: '20px', md: '45px' }}
            />
          </a>
          <a href='https://www.linkedin.com/company/tenmilezone/'>
            <Image
              src={linkedin}
              alt='linkedIn'
              loading='lazy'
              width={{ base: '20px', md: '45px' }}
              height={{ base: '20px', md: '45px' }}
            />
          </a>
        </Stack>
      </Flex>
    </Stack>
  );
};

const FormSection = () => {
  return (
    <Stack
      alignItems='center'
      paddingY={{ base: '1em', md: '3em' }}
      paddingBottom={{ base: '3em', md: '0' }}
      backgroundColor='white'
      position='relative'
      minHeight='100vh'
      maxHeight='100vh'
      overflow='hidden'
      paddingX='1em'
    >
      <Heading color='#090842' fontSize={{ base: '2xl', md: '2.4rem' }}>
        Become a Partner
      </Heading>
      <Text
        color='#06083E'
        paddingBottom='2em'
        fontSize={{ base: 'xl', md: '1.4rem' }}
        textAlign='center'
        display={{ base: 'none', md: 'inherit' }}
      >
        FILL OUT OUR FORM BELLOW AND BE PROFESSIONAL AND SUCCESSFUL WITH US.
      </Text>
      {/* Form card */}

      <Stack
        padding='2em'
        boxShadow='5px 5px 42px #0101015E'
        maxWidth='575px'
        width='100%'
        borderRadius='10px'
        gap='1.5em'
        zIndex={100}
        backgroundColor='white'
      >
        <Input
          borderColor='#040D2F'
          focusBorderColor='#040D2F'
          placeholder='First name *'
          required
        />
        <Input
          borderColor='#040D2F'
          focusBorderColor='#040D2F'
          placeholder='Last name *'
          required
        />
        <Input
          borderColor='#040D2F'
          focusBorderColor='#040D2F'
          placeholder='Organization name *'
          required
        />
        <Input
          borderColor='#040D2F'
          focusBorderColor='#040D2F'
          placeholder='Email *'
          required
        />
        <Textarea
          borderColor='#040D2F'
          focusBorderColor='#040D2F'
          placeholder='Message'
          resize='none'
          height='134px'
        />
        <Button
          backgroundColor='#06083E'
          _hover={{ backgroundColor: '#080b4f' }}
          color='white'
        >
          Send message
        </Button>
      </Stack>

      {/* Bottom rights message */}
      <Text
        color='white'
        position='absolute'
        bottom={0}
        zIndex={90}
        fontSize={{ base: '.6rem', md: '.8rem' }}
        paddingBottom='1em'
      >
        © 2022 www.greenwaveiot.com All Rights Reserved.
      </Text>

      {/* Bottom curve */}
      <Image
        src={curve}
        alt='curve'
        position='absolute'
        top='auto '
        bottom={{ base: '-13px', md: '-100px' }}
        right={{ base: '-13px', md: '-100px' }}
        transform={{ base: 'scale(1.3)', md: 'none' }}
        zIndex={10}
      />
    </Stack>
  );
};

const Partners = () => {
  return (
    <>
      <TopSection />
      <FormSection />
    </>
  );
};

export default Partners;
