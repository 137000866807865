import { Link, NavLink, useNavigate } from 'react-router-dom'

import LogoComponent from 'components/Logo'

import { TransparentHeaderStyle } from './style'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Stack,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { HamburgerIcon } from '@chakra-ui/icons'

const TransparentHeader = () => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<any>()
  const [isLessThan768] = useMediaQuery('(max-width: 768px)')

  return (
    <TransparentHeaderStyle>
      <LogoComponent light={false} onClick={() => navigate('/home')} />
      <Flex
        as='nav'
        alignItems='center'
        gap='1em'
        display={{ base: 'none', md: 'flex' }}
      >
        <NavLink to={'/home'}>Home</NavLink>
        <NavLink to={'/who-we-are'}>Who We Are</NavLink>
        <NavLink to={'/solutions'}>Solutions</NavLink>
        <NavLink to={'/partners'}>Partner</NavLink>
        <NavLink to={'/careers'}>Careers</NavLink>
        <Link className='get-in-touch' to={'/sign-in'}>
          Sign In
        </Link>
      </Flex>
      <IconButton
        display={{ base: 'block', md: 'none' }}
        backgroundColor='transparent'
        aria-label='Open Drawer'
        color='white'
        icon={<HamburgerIcon />}
        ref={btnRef}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size='full'
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor='#06083E' opacity={0.75}>
          <DrawerCloseButton color='white' />
          <DrawerBody>
            <Stack
              as='nav'
              gap='1em'
              color='white'
              fontWeight={700}
              paddingLeft='1em'
              paddingTop='1em'
            >
              <NavLink to={'/home'}>Home</NavLink>
              <NavLink to={'/who-we-are'}>Who We Are</NavLink>
              <NavLink to={'/solutions'}>Solutions</NavLink>
              <NavLink to={'/partners'}>Partner</NavLink>
              <NavLink to={'/careers'}>Careers</NavLink>
              <NavLink to={'/sign-in'}>Sign in</NavLink>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </TransparentHeaderStyle>
  )
}

export default TransparentHeader
